import { useMemo } from "react";
import clsx from "clsx";

import { PostCard } from "@/component/postCard";
import { SimplePost } from "@/model";

import style from "./index.module.scss";

type Props = {
  posts: SimplePost[];
  className?: string;
};

export function BlogGrid(props: Props): JSX.Element {
  const { posts, className } = props;
  const cards = useMemo(
    () =>
      posts.map(post => {
        const { slug } = post;
        return <PostCard post={post} key={slug} className={style.card} />;
      }),
    [posts]
  );
  const classNames = useMemo(() => clsx(style.grid, className), [className]);
  return <div className={classNames}>{cards}</div>;
}
