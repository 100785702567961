import { Link } from "gatsby";

import { SimplePost } from "@/model";
import { PublishDate } from "@/component/publishDate";
import { Tags } from "@/component/tag";

import { Thumbnail } from "./thumbnail";
import style from "./index.module.scss";

type Props = {
  post: SimplePost;
  className?: string;
};

export function PostCard(props: Props): JSX.Element {
  const { post, className } = props;
  const { path, cover, title, publishDate, tags } = post;
  const { fluid } = cover;
  return (
    <Link to={path} className={className}>
      <section className={style.card}>
        <Thumbnail fluid={fluid} title={title} />
        <div className={style.content}>
          <h2 className={style.title}>{title}</h2>
          <PublishDate publishDate={publishDate} className={style.date} />
          <Tags tags={tags} tagClassName={style.tag} />
        </div>
      </section>
    </Link>
  );
}
