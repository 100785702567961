import { DiscussionEmbed, CommentCount as DisqusCount } from "disqus-react";

import style from "./index.module.scss";

type Props = {
  pathname: string;
  origin: string;
  title: string;
};

export function Comment(props: Props): JSX.Element {
  const { pathname, origin, title } = props;
  const disqusConfig = {
    url: origin + pathname,
    identifier: pathname,
    title
  };
  if (process.env.NODE_ENV === "development") {
    return <div className={style.wrapper} />;
  }
  return (
    <div className={style.wrapper}>
      <DiscussionEmbed shortname="acg-avalon" config={disqusConfig} />
    </div>
  );
}

export function CommentCount(props: Props): JSX.Element {
  const { pathname, origin, title } = props;
  const disqusConfig = {
    url: origin + pathname,
    identifier: pathname,
    title
  };
  return <DisqusCount shortname="acg-avalon" config={disqusConfig} />;
}
