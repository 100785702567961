import { useMemo } from "react";
import clsx from "clsx";

import { HoverButton } from "@/component/button";

import style from "./index.module.scss";

type Props = {
  next?: string;
  prev?: string;
  className?: string;
};
export function Pagination(props: Props): JSX.Element {
  const { next, prev, className } = props;
  const prevPage = useMemo(
    () => (prev ? <HoverButton link={prev}>上一頁</HoverButton> : null),
    [prev]
  );
  const nextPage = useMemo(
    () => (next ? <HoverButton link={next}>下一頁</HoverButton> : null),
    [next]
  );
  const classNames = useMemo(() => clsx(style.pagination, className), [
    className
  ]);
  if (!prev && !next) {
    return <></>;
  }
  return (
    <nav className={classNames}>
      {prevPage}
      {nextPage}
    </nav>
  );
}
