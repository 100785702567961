import { ReactNode, useMemo } from "react";
import clsx from "clsx";

import style from "./index.module.scss";

type Props = {
  className?: string;
  children?: ReactNode;
  top?: boolean;
  padding?: boolean;
};
export function Container(props: Props): JSX.Element {
  const { className, children, top = false, padding = false } = props;
  const classNames = useMemo(
    () =>
      clsx(
        style.section,
        {
          [style.top]: top,
          [style.padding]: padding
        },
        className
      ),
    [className, padding, top]
  );
  return <section className={classNames}>{children}</section>;
}
