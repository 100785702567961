import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./icon.module.scss";

export type IconType = IconProp;
export type IconProps = {
  className?: string;
  type: IconType;
  title?: string;
};

export function Icon(props: IconProps): JSX.Element {
  const { className, type, title } = props;
  const faProps = {
    className,
    title,
    icon: type
  };
  return <FontAwesomeIcon {...faProps} />;
}
