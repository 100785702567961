import { MouseEventHandler, useCallback, useMemo } from "react";
import clsx from "clsx";
import { navigate } from "gatsby";

import style from "./tag.module.scss";

type Props = {
  tag: string;
  className?: string;
};
export function Tag(props: Props): JSX.Element {
  const { tag, className } = props;
  const classNames = useMemo(() => clsx(style.tag, className), [className]);
  const onClick: MouseEventHandler<HTMLElement> = useCallback(
    e => {
      e.preventDefault();
      navigate(`/tag/${tag}`);
    },
    [tag]
  );

  const onMouseEnter: MouseEventHandler<HTMLElement> = useCallback(() => {
    if (window && "___loader" in window) {
      const loader: any = window["___loader"];
      loader.enqueue(`/tag/${tag}`);
    }
  }, [tag]);
  return (
    <div className={classNames} onClick={onClick} onMouseEnter={onMouseEnter}>
      {tag}
    </div>
  );
}
