import { useMemo } from "react";
import clsx from "clsx";

import { Tag } from "./tag";
import style from "./index.module.scss";

type Props = {
  tags: string[];
  className?: string;
  tagClassName?: string;
};
export function Tags(props: Props): JSX.Element {
  const { tags, className, tagClassName } = props;
  const t = tags.map(tag => (
    <Tag tag={tag} key={tag} className={tagClassName} />
  ));
  const classNames = useMemo(() => clsx(style.tags, className), [className]);
  return <div className={classNames}>{t}</div>;
}
