import { useMemo } from "react";
import clsx from "clsx";

import { Icon } from "@/component/icon";

import style from "./index.module.scss";

type Props = {
  publishDate: Date;
  className?: string;
};

export function PublishDate(props: Props): JSX.Element {
  const { publishDate, className } = props;
  const classNames = useMemo(() => clsx(style.date, className), [className]);
  return (
    <div className={classNames} title="發佈日期">
      <Icon type={["far", "calendar-alt"]} className={style.icon} />
      <div>{publishDate}</div>
    </div>
  );
}
