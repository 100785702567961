import { Post } from "@/model";

import style from "./index.module.scss";

type Props = {
  post: Post;
};

export function Article(props: Props): JSX.Element {
  const { post } = props;
  const { html } = post;
  return (
    <article
      className={style.article}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}
