import { config, library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

export const IconClass = "fa-svg";
config.autoAddCss = false;
config.replacementClass = IconClass;

library.add(faCalendarAlt);

export * from "./icon";
