import { ReactNode } from "react";
import { Link } from "gatsby";
import clsx from "clsx";

import style from "./hoverButton.module.scss";

type Props = {
  link: string;
  children?: ReactNode;
};

export function HoverButton(props: Props): JSX.Element {
  const { link, children } = props;
  const className = clsx(style.button, style.type1);
  return (
    <Link to={link} className={className}>
      {children}
    </Link>
  );
}
