import Img, { FluidObject } from "gatsby-image";

import style from "./thumbnail.module.scss";

type Props = {
  fluid: FluidObject;
  title: string;
};
export function Thumbnail(props: Props): JSX.Element {
  const { fluid, title } = props;
  return <Img fluid={fluid} alt={title} className={style.cover} />;
}
